import React from "react";

const Header = () => (
  <div id="head" className="head">
    <h1 className="title">Schaika</h1>
    <div>
      React.js synthesizer{" "}
    </div>
  </div>
);

export default Header;
